<template>
    <div id="container">
        <div class="header">
                <van-nav-bar
                title=""
                left-text="返回"
                right-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                />
            </div>
            <div class="logo">
                <!-- <img src="../../assets/images/title.png" alt=""> -->
                    <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
            </div>
            <!--  -->
            <div class="content">
                <div class="info">
                    <div class="logins">
                        <div class="title">今日登陆</div>
                        <div class="num">
                            5555
                        </div>
                    </div>
                    <div class="zhuce">
                        <div class="title">今日注册</div>
                        <div class="num">
                            455
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex"
import { resetState,upbyhand,searchUser } from "../../model/user"
import { Toast } from 'vant';
// import axios from 'axios'



export default {
    data() {
        return {
            username:'',
            phone:"",
            FileName: "JsToJsonFile",
            ShowListData: [],
            infoEnd:[]
        }
    },
    computed:{
         ...mapMutations(["changeFlag","changeFlag1"]),
    },
    created() {
        // this.getTreeData()

        // this.getInfo()
    },
    methods: {
       
       
       

        search(){
            let data = {
                username : this.username
            }
            searchUser(data).then((res)=>{
                console.log(res,777);
                if(res.data.data.length && res.data.data.length == 1){
                     Toast.success('查询成功')
                }else{
                    Toast.fail('查询失败')
                }
                
            })
        },
        hysj(){
            let data = {
                username : this.phone
            }
            upbyhand(data).then((res)=>{
                console.log(res);
                Toast.success('升级成功')
            })
        },
        reset(){
          let data = {
            loginState : 1
          }
          resetState(data).then((res)=>{
            console.log(res);
            Toast.success('修改成功')
          })
        },
        clean(){
            this.phone = ""
            this.username = ""
        },
        onClickLeft(){
            window.history.back(-1)
        },
        onClickRight(){

        }
    },
   async mounted() {
    // this.ces = true
      // this.ces = true
    this.changeFlag();
  },
  destroyed() {
    this.changeFlag1();
  },

}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
#container .content .info{
    width: 100%;
    height: 80px;
    padding: 5px;
    display: flex;
}
#container .content .info .logins{
    width: 50%;
}
#container .content .info .logins .title{
    width: 100%;
    height: 30px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
    /* color: #ec4899; */
}
#container .content .info .logins .num{
    font-size: 22px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ec4899;
}
#container .content .info .zhuce{
    width: 50%;
}
#container .content .info .zhuce .title{
    width: 100%;
    height: 30px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
    /* color: #ec4899; */
}
#container .content .info .zhuce .num{
    font-size: 22px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ec4899;
}
/* #container .content .info .title{
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
} */
#container .content .info .shengji{
  width: 100%;
  height: 50px;
  font-size: 14px;
}
#container .content .info .shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
#container .content .info .shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
#container .content .info li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
</style>